import { IDonationCampaign } from 'models/donationCampaign';

import { EntityService } from './entityService';
import HttpRequestor from './httpRequestor';

export class DonationCampaignClientService extends EntityService<IDonationCampaign> {
  constructor(httpRequestor: HttpRequestor) {
    super(httpRequestor, 'donation_campaign');
  }

  async getAllDonationsCampaigns(): Promise<IDonationCampaign[]> {
    const { data } = await this.getAll();
    return data;
  }
  // Write down the specifics when needed
}
