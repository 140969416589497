import { IStandardSetting } from 'models/standardSetting';

import { EntityService } from './entityService';
import HttpRequestor from './httpRequestor';

export class SettingClientService extends EntityService<IStandardSetting> {
  constructor(httpRequestor: HttpRequestor) {
    super(httpRequestor, 'standard_setting');
  }

  async getAllSettings(): Promise<any> {
    const { data } = await this.getAll();
    return data.reduce((acc, current: IStandardSetting) => {
      return {
        ...acc,
        [current.name]: {
          value: current.value,
          jsonValue: current.jsonValue,
        },
      };
    }, {});
  }
  // Write down the specifics when needed
}
