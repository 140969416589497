import moment from 'moment';

const verifyWhetherDisplayBlackBackgroundCampaign = (
  startDateString: string,
  endDateString: string,
  nowString: string,
) => {
  const startDate = moment(startDateString)
    .tz('Australia/Sydney')
    .startOf('day')
    .utc();

  const endDate = moment(endDateString)
    .tz('Australia/Sydney')
    .endOf('day')
    .utc();

  const now = moment(nowString).tz('Australia/Sydney').utc();

  const blackBackgroundCampaignIsRunning = now.isBetween(
    startDate,
    endDate,
    null,
    '[]',
  );

  if (blackBackgroundCampaignIsRunning) {
    const diffsInDays = now.diff(startDate, 'days');
    return diffsInDays % 2 === 0;
  }

  return false;
};

export default verifyWhetherDisplayBlackBackgroundCampaign;
